import { $PROXY, createContext, JSX, splitProps, useContext } from "solid-js";

import UBoolean from "@repo/utils/UBoolean";
import UObject from "@repo/utils/UObject";

export default function createPropsProvider<
  Props extends Record<any, any>,
  Ctx extends Partial<Props> = Partial<Props>,
>(
  key: string,
  mergers?: Partial<{
    [Key in keyof Props]?: (context: Ctx, props: Props) => Props[Key];
  }>
) {
  if (createPropsProvider.cache[key])
    return createPropsProvider.cache[key] as never;

  const allMergers = {
    ...createPropsProvider.defaultMergers,
    ...mergers,
  };
  const Context = createContext({} as Ctx);

  type ProviderProps = Ctx & {
    children: JSX.Element;
  };

  function PropsProvider(props: ProviderProps) {
    const context = useContext(Context);
    const [local, rest] = splitProps(props, ["children"]);

    return (
      <Context.Provider value={PropsProvider.merge(context, rest)}>
        {local.children}
      </Context.Provider>
    );
  }

  PropsProvider.merge = <P extends Record<any, any>>(context: Ctx, props: P) =>
    new Proxy(
      {
        get(p: any) {
          return (allMergers as any)[p]
            ? (allMergers as any)[p](context, props)
            : p in props
              ? props[p]
              : context[p];
        },
        has(p) {
          return p in context || p in props;
        },
        keys() {
          return [...new Set([...Object.keys(context), ...Object.keys(props)])];
        },
      },
      propTraps
    ) as unknown as Ctx & P;

  PropsProvider.useContext = () => useContext(Context);
  PropsProvider.useMerge = <P extends {}>(props: P) =>
    PropsProvider.merge(PropsProvider.useContext(), props);

  createPropsProvider.cache[key] = PropsProvider;
  return PropsProvider;
}

createPropsProvider.defaultMergers = {
  class: (context: any, props: any) =>
    [context.class, props.class].filter(Boolean).join(" "),
  // propsFor: (context: any, props: any) =>
  //   PropsFor.merge(context.propsFor, props.propsFor),
};

// Maintain references in dev refresh
createPropsProvider.cache = {} as Record<string, any>;

const propTraps: ProxyHandler<{
  get: (k: UObject.Key) => any;
  has: (k: UObject.Key) => boolean;
  keys: () => string[];
}> = {
  get(_, property, receiver) {
    if (property === $PROXY) return receiver;
    return _.get(property);
  },
  has(_, property) {
    if (property === $PROXY) return true;
    return _.has(property);
  },
  set: UBoolean.returnsTrue,
  deleteProperty: UBoolean.returnsTrue,
  getOwnPropertyDescriptor(_, property) {
    return {
      configurable: true,
      enumerable: true,
      get() {
        return _.get(property);
      },
      set: UBoolean.returnsTrue,
      deleteProperty: UBoolean.returnsTrue,
    };
  },
  ownKeys(_) {
    return _.keys();
  },
};
